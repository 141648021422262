<template>
	<div>
		<b-card title="Transaction">
			<!-- <form @submit.prevent="submitFilter"> -->
				<b-row>
					<b-col cols="12" lg="3">
						<div class="form-group">
							<label>Tipe:</label>
							<select 
								class="form-control"
								v-model="link"
								@change="getData"
							>
								<option value="services">Service</option>
								<option value="packages">Paket</option>
							</select>
						</div>
					</b-col>
					<b-col cols="12" lg="3">
						<div class="form-group">
							<label>Status:</label>
							<select 
								class="form-control"
								v-model="filter.status" 
							>
								<option value="">-- Pilih Status --</option>
								<option value="success">Sukses</option>
								<option value="failed">Gagal</option>
								<option value="pending">Pending</option>
								<option value="expired">Kadaluarsa</option>
							</select>
						</div>
					</b-col>
					<b-col cols="12" lg="3">
						<div class="form-group">
							<label>Date:</label>
							<flat-pickr
								v-model="filter.daterange"
								class="form-control"
								placeholder="Search: Date"
								:config="{ 
									mode: 'range',
									locale: {
										rangeSeparator: ' - ',
									},
								}"
							/>
						</div>
					</b-col>
					<b-col cols="12" lg="3">
						<div class="form-group">
							<label>Customer:</label>
							<input 
								type="text" 
								class="form-control"
								placeholder="Search: Email, Nama, No.Hp"
								v-model="filter.customer" 
							>
						</div>
					</b-col>
				</b-row>
				<b-col cols="12" class="p-0 mt-2">
					<button 
						:disabled="!filter.status && !filter.daterange" 
						@click="filter.status = '' , filter.daterange = ''" 
						class="btn btn-outline-secondary mr-2"
					>
						Reset
					</button>
					<button
						variant="primary"
						v-ripple.400="'rgba(113, 102, 240, 0.15)'"
						v-b-modal.modalExport
						class="btn waves-effect waves-float waves-light btn-primary"
						@click="getDataExport"
					>
						Export
					</button>
					<b-modal
						id="modalExport"
						title="Export"
						hide-footer
						size="lg"
					>
						<form>
							<b-col cols="12" class="text-center mb-2">
								<button
									variant="primary"
									class="btn waves-effect waves-float waves-light btn-primary"
									@click.prevent="exportNow"
								>
									Export Sekarang
								</button>
							</b-col>

							<b-table 
								striped 
								hover 
								:items="exportHistory" 
								:fields="tableExport"
								:busy="isLoadingExport"
								show-empty
								responsive
							>
								<template #thead-top>
									<tr>
										<th variant="primary" colspan="12" class="text-center">Export Log</th>
									</tr>
								</template>

								<template #table-busy>
									<div class="text-center text-secondary my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>Loading...</strong>
									</div>
								</template>
								
								<template v-slot:cell(no)="{ index }">
									{{ index + 1 }}
								</template>

								<template v-slot:cell(waktu_export)="{ item }">
									{{ item.exported_at }}
								</template>

								<template v-slot:cell(file_export)="{ item }">
									<a :href="item.path">{{ item.path }}</a>
								</template>
							</b-table>
						</form>
					</b-modal>
				</b-col>
			<!-- </form> -->
			<div class="border__dash"></div>
			<b-table 
				striped 
				hover 
				:items="transaction" 
				:fields="this.link == 'packages' ? tablePackages : tableServices"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				
				<template v-slot:cell(no)="{ index }">
					{{ (currentPage - 1) * per_page + index + 1 }}
				</template>
				
				<template v-slot:cell(invoice_number)="{ item }">
					{{ item.invoice_number ? item.invoice_number : '-' }}
				</template>
				
				<template v-slot:cell(customer)="{ item }">
					{{ item.customer ? item.customer.name : '-' }}
				</template>

				<template v-slot:cell(actions)="{ item }">
					<router-link
						:to="`/transaction-detail/${link}/`+ item.uuid"
					>
						<b-badge 
							variant="primary" 
							class="d-flex align-items-center badge-glow"
							style="gap:5px;"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-send-icon`"
								icon="AlertCircleIcon"
								class="cursor-pointer"
								size="12"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Detail Data'"
							/>
								Detail
						</b-badge>
					</router-link>
				</template>

				<template v-slot:cell(status)="{ item }">
					<b-badge
						variant="light-success"
						v-if="item.status == 'success'"
					>
						Sukses
					</b-badge>
					<b-badge
						variant="light-danger"
						v-if="item.status == 'failed'"
					>
						Gagal
					</b-badge>
					<b-badge
						variant="light-warning"
						v-if="item.status == 'pending'"
					>
						Pending
					</b-badge>
					<b-badge
						variant="light-secondary"
						v-if="item.status == 'expired'"
					>
						Kadaluarsa
					</b-badge>
				</template>

			</b-table>
			<div
				v-if="result.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	BButton,
	BSpinner,
	VBTooltip,
	VBModal,
	BRow,
	BBadge,
	BCol
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BRow,
		BBadge,
		BCol,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-tooltip': VBTooltip,
	'b-modal': VBModal,
		Ripple,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			result: {},
			isLoading: false,
			isLoadingExport: false,
			tablePackages: ['no', 'invoice_number', 'customer','date', 'price', 'total', 'status', 'actions'],
			tableServices: ['no', 'invoice_number', 'customer','date', 'amount', 'total', 'status', 'actions'],
			tableExport: ['no', 'waktu_export', 'file_export'],
			transaction: [],
			exportHistory: [],
			per_page: 10,
			// filter: {},
			link:'services',
			filter: {
				status: '',
				daterange: '',
				customer: ''
			},
			validations: '',
		}
	},

	computed: {
		rows() {
			return this.transaction.length, this.exportHistory.length
		}
	},

	methods: {
		exportNow() {
			const exportParam = this.filter
			exportParam.per_page = null
			let url = this.link
			this.$http.post(`admin/transactions/${url}/export`, exportParam).then(response => {
				if(response) {
					this.$swal({
						title: 'Sukses',
						icon: 'success',
						text: 'Data sedang di export di background, mohon tunggu beberapa saat.',
						type: 'warning',
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						buttonsStyling: false,
					})
					this.$bvModal.hide('modalExport')
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Filter Date', this.validations.daterange.join(' '))
				}
			})
		},
		getData(page) { 
			this.isLoading = true
			let url = this.link
			const queryParams = this.filter
			queryParams.per_page = this.per_page
			queryParams.page = page
			this.$http.get('admin/transactions/'+ url, {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.transaction = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
		getDataExport() {
			this.isLoadingExport = true
			let url = this.link
			this.$http.get('admin/exports/history/' + url
			).then(response => {
				this.exportHistory = response.data.data
				this.isLoadingExport = false
			})
		}
	},

	created() {
		this.getData()
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
